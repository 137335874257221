import React from 'react';

import ManageAreas from 'src/components/areas/manage-areas';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const ManageAreasPage = () => {
  return (
    <Layout>
      <SEO title="Manage Areas" />
      <PrivateRoute>
        <ManageAreas />
      </PrivateRoute>
    </Layout>
  );
};

export default ManageAreasPage;
